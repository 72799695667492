import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  p: 3,
};

export default function InspectorModal({ open, handleClose, setId, getData }) {
  const [text, setText] = React.useState();
  const token = localStorage.getItem('accessToken');

  const notify = () => toast('Inspector Assigned Successfully');

  const addPosts = async () => {
    try {
      const res = await axios.post(
        `https://api.simplespect.com/api/v1/user/update_inspector/${setId}`,
        {
          inspectorName: text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': process.env.REACT_APP_API_KEY,
            platform: process.env.REACT_APP_platform,
          },
        }
      );
      setText('');
      getData();
      handleClose();

      if (res?.data?.status) {
        notify();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <p style={{ fontWeight: 'bold' }}>Enter Inspector Name</p>

            <input style={{marginBottom: '10px'}}
              id='outlined-basic'
              label='Add Inspoctor'
              variant='outlined'
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            
            <div style={{ width: '100%' }}>
              <Button
                variant='contained'
                style={{ width: '20%' }}
                onClick={() => addPosts()}
              >
                Submit
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
